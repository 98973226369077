import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import { SvcRequestsRepository } from '@skelloapp/svc-requests-sdk';
import { svcRequestsUrl } from '@config/env';
import { authClient } from './auth_client';
import {
  sendRequestConfig,
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
} from './http_client';

class SvcRequestsClient {
  constructor() {
    const httpClient = new HttpClient(svcRequestsUrl, {
      clientSource: 'skelloApp:web',
      timeout: 20000,
    },
    );

    httpClient.clientWithRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithRetry.interceptors.response.use(
      successReceivedRequestConfig,
      error => errorReceivedRequestConfig(error, httpClient.clientWithRetry),
    );

    httpClient.clientWithoutRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithoutRetry.interceptors.response.use(
      successReceivedRequestConfig,
      error => errorReceivedRequestConfig(error, httpClient.clientWithoutRetry),
    );
    this.svcRequestsRepository = new SvcRequestsRepository(httpClient);
  }

  async updateLeaveRequest(leaveRequestId, params) {
    const jwt = await this.getAuthToken();

    return this.svcRequestsRepository.updateById(leaveRequestId, params, jwt);
  }

  async createLeaveRequest(params) {
    const jwt = await this.getAuthToken();

    return this.svcRequestsRepository.create(params, jwt);
  }

  async getPreSelectedManager({ employeeId, shopId, managerIds }) {
    const jwt = await this.getAuthToken();

    return this.svcRequestsRepository.getPreSelectedManager(
      { employeeId, shopId, managerIds },
      jwt,
    );
  }

  async getAllLeaveRequests(params) {
    const jwt = await this.getAuthToken();
    const response = await this.svcRequestsRepository.getAllLeaveRequests(params, jwt);

    return response;
  }

  async getLeaveRequest(leaveRequestId) {
    const jwt = await this.getAuthToken();
    const response = await this.svcRequestsRepository.getById(leaveRequestId, jwt);

    return response;
  }

  async deleteLeaveRequest(leaveRequestId) {
    const jwt = await this.getAuthToken();
    return this.svcRequestsRepository.deleteById(leaveRequestId, jwt);
  }

  async getAuthToken() {
    const { token } = await authClient.getAuthToken();

    return token;
  }
}

export default SvcRequestsClient;
export const svcRequestsClient = new SvcRequestsClient();
