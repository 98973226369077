import axios from 'axios';
import { AuthWebInterceptor } from '@skelloapp/skello-auth-client';
import { skelloApiBaseUrl } from '@config/env';
import { authClient } from './auth_client';
import { BaseHeaders } from './constant_client';

export const httpClient = axios.create({
  headers: BaseHeaders,
  baseURL: skelloApiBaseUrl,
});

export const unauthenticatedHttpClient = axios.create({
  // no interceptor with token
  headers: BaseHeaders,
  baseURL: skelloApiBaseUrl,
});

export const msHttpClient = axios.create({
  headers: BaseHeaders,
});

export const sendRequestConfig = async config => {
  window.activeVueResources = window.activeVueResources || 0;
  window.activeVueResources += 1;

  const authToken = await authClient.getAuthToken();

  if (authToken) {
    return new AuthWebInterceptor(authClient.authClient, {
      token: authToken.token,
      refreshToken: authToken.refreshToken,
    }).request(config);
  }

  return config;
};

export const successReceivedRequestConfig = async config => {
  window.activeVueResources = window.activeVueResources || 0;
  window.activeVueResources += 1;

  return config;
};

export const errorReceivedRequestConfig = async (error, client) => {
  window.activeVueResources -= 1;

  const authToken = await authClient.getAuthToken();

  if (!authToken) throw error;

  await new AuthWebInterceptor(
    authClient.authClient,
    {
      token: authToken.token,
      refreshToken: authToken.refreshToken,
    },
  )
    .responseError(error, async data => {
      await authClient.setAuthToken(data);
      error.config.headers.Authorization = `Bearer ${data.token}`;

      return client.request(error.config);
    });

  throw error;
};

httpClient.interceptors.request.use(sendRequestConfig);
httpClient.interceptors.response.use(
  successReceivedRequestConfig,
  error => errorReceivedRequestConfig(error, httpClient),
);

msHttpClient.interceptors.request.use(sendRequestConfig);
msHttpClient.interceptors.response.use(
  successReceivedRequestConfig,
  error => errorReceivedRequestConfig(error, msHttpClient),
);
