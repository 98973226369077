import {
  onboardingFrontendServerUrl, svcBillingAutomationUrl,
} from '@config/env';
import { FactoryBillingAutomationWeb } from '@skelloapp/svc-billing-automation-sdk';
import { authClient } from './auth_client';

// We need to set a higher timeout on the frontend than the backend (currently at 10s)
const AXIOS_TIMEOUT = 12000;

class SvcBillingAutomationClient {
  constructor() {
    this.factory = FactoryBillingAutomationWeb.create(svcBillingAutomationUrl, {
      clientSource: 'skelloApp:web',
      timeout: AXIOS_TIMEOUT,
    });
  }

  async createPortalSessionByChargebeeId(chargebeeId) {
    const { token } = await authClient.getAuthToken();

    return this.factory.portalSession.createByChargebeeId(chargebeeId, token);
  }

  async createSelfServeChargebeeId(prospectParams) {
    return this.factory.selfServe.createSelfServeChargebeeId(prospectParams);
  }

  async updateSelfServeSubscription(params) {
    const { token } = await authClient.getAuthToken();

    return this.factory.selfServe.updateSelfServeSubscription(params, token);
  }

  async getChargebeeIdsFromSalesforce(organisationId) {
    const { token } = await authClient.getAuthToken();

    return this.factory.organisation.getChargebeeIds(organisationId, token);
  }

  async getContractDetails(organisationId) {
    const { token } = await authClient.getAuthToken();

    return this.factory.organisation.getContractDetails(organisationId, token);
  }

  async getCompanyRegistrationsByReference(companyReference) {
    return this.factory.companyRegistration.getCompanyRegistrationsByReference(companyReference);
  }

  async getCompanyRegistrationsByName(companyName) {
    return this.factory.companyRegistration.getCompanyRegistrationsByName(companyName);
  }

  async getCompanyRegistrationsByShopRegistrationNumber(shopRegistrationNumber) {
    return this.factory.companyRegistration
      .getCompanyRegistrationsByShopRegistrationNumber(shopRegistrationNumber);
  }

  async createChargebeeSource(
    chargebeeId,
    city,
    country,
    email,
    firstName,
    iban,
    lastName,
    line1,
    postalCode) {
    return this.factory.chargebee.createSource(
      chargebeeId,
      city,
      country,
      email,
      firstName,
      iban,
      lastName,
      line1,
      postalCode,
    );
  }

  async updateBillingInformations(organisationId, billingInformations) {
    const { token } = await authClient.getAuthToken();

    return this.factory.chargebee.updateBillingInformations(
      organisationId,
      billingInformations,
      token,
    );
  }

  async getSources(chargebeeIds) {
    const { token } = await authClient.getAuthToken();

    return this.factory.chargebee.getSources(chargebeeIds, token);
  }

  async createQuoteByOrgaId(organisationId, userFirstName, userLastName, userEmail) {
    const { token } = await authClient.getAuthToken();

    return this.factory.organisation.createQuoteByOrgaId(
      organisationId,
      userFirstName,
      userLastName,
      userEmail,
      token,
    );
  }

  async redirectUserToDealroom(quoteId, parentClusterNodeId, locale) {
    const { token } = await authClient.getAuthToken();

    const params = new URLSearchParams({
      id: decodeURIComponent(quoteId),
      t: token,
    });

    // To mitigate user changing URL parameter causing security issue
    // we base64 encode parentClusterNodeId value and obfuscate token and cluster keys
    // NOTE: this isn't a proper solution but a mitigation
    if (parentClusterNodeId) {
      params.append('c', btoa(parentClusterNodeId));
    }

    if (locale) {
      params.append('locale', locale);
    }

    window.location = `${onboardingFrontendServerUrl}/contract_change/create_shop?${params.toString()}`;
  }
}

export const svcBillingAutomationClient = new SvcBillingAutomationClient();

export default svcBillingAutomationClient;
