import { svcPosBaseUrl } from '@config/env';
import { ChiftRepository } from '@skelloapp/svc-pos-sdk';
import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import {
  sendRequestConfig,
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
} from './http_client';

class SvcPosClient {
  constructor() {
    const httpClient = new HttpClient(svcPosBaseUrl, {
      clientSource: 'skelloApp:web',
    });

    httpClient.clientWithRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithRetry.interceptors.response.use(
      successReceivedRequestConfig,
      error => errorReceivedRequestConfig(error, httpClient.clientWithRetry),
    );

    httpClient.clientWithoutRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithoutRetry.interceptors.response.use(
      successReceivedRequestConfig,
      error => errorReceivedRequestConfig(error, httpClient.clientWithoutRetry),
    );

    this.svcPosRepository = new ChiftRepository(httpClient);
  }

  async getProviders() {
    return this.svcPosRepository.getProviders();
  }

  async activateProvider({ name, organisationId, providerId, providerName, shopId }) {
    return this.svcPosRepository.activateProvider(
      { name, organisationId, providerId, providerName, shopId },
    );
  }

  async getLocations(organisationId) {
    return this.svcPosRepository.getLocations(
      { organisationId },
    );
  }

  async deleteProvider(organisationId) {
    return this.svcPosRepository.deleteProvider(
      organisationId,
    );
  }

  async deleteShopProvider(organisationId, shopId) {
    return this.svcPosRepository.deleteShopProvider(
      organisationId, shopId,
    );
  }
}

export const svcPosClient = new SvcPosClient();

export default svcPosClient;
